import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import globalVariables from '../Global.js';
import { useLocation, useNavigate } from 'react-router-dom';

// Create the AuthContext with a default value of null
const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let location = useLocation();
  const storedFrom = localStorage.getItem('from');
  const initialFrom = location.state?.from?.pathname || storedFrom || "/";
  //const initialFrom = "/index";
  const [from, setFrom] = useState(initialFrom);
  
  
  const verifyServerToken = (token) => {
    console.error(from)
    return new Promise((resolve, reject) => {
      const form_data = { token };
      axios.post(globalVariables.api_url + '/TokenAuthentication/isToken_Authorized', form_data, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
    });
  };

  useEffect(() => {
    const token = Cookies.get('token'); 
    console.log("token loading");
    console.log(token);
    if (token) {
      console.log('Token and username found, verifying server token...');
      verifyServerToken(token)
        .then(response_data => {
        //  console.log('Server token verified, setting user...');
          Cookies.set('token', response_data.token, {expires: 365, sameSite: 'None', secure: true });
      
           axios.defaults.headers.common['Authorization'] = `Bearer ${response_data.token}`;
          
          signin(response_data.user, () => {
            console.log('from value',from);
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
           // localStorage.setItem('from', from);
            navigate(from, { replace: true });
          })
        })
        .catch(error => {
          console.error('Error in verifyServerToken:', error);
          setUser(null);
        })
        .finally(() => setLoading(false));
    } else {
      setUser(null);
      setLoading(false);
    }
  }, []);

  const signin = (user, callback) => {
    console.log("okkkkkccccccccckkkkk");
    setUser(user);
   
    setTimeout(callback, 200);
   callback();
   console.log("login sucessfull");
  };


 const signout = (callback) => {
  setUser(null);
  localStorage.removeItem('from');
navigate('/', { replace: true, state: {} }); // Clear location.state by navigating with a new state
  callback();
};


  const value = { user, signin, signout };

  if (loading) {
    return <div>Loading...</div>; // Render a loading indicator while verifying
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
