import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AuthProvider } from './providers/AuthProvider';
import RequireAuth from './RequireAuth.js';
import RequireRole from './providers/RequireRole';
// import Deathdetails from './ui-components/Deathdetails.js';

const MasterLayout = lazy(() => import('./ui-components/MasterLayout'));
const Dashboard = lazy(() => import('./ui-components/Dashboard'));
const Designations = lazy(() => import('./ui-components/Designations'));
const YearSettings = lazy(() => import('./ui-components/Year_settings'));
const ChangePassword = lazy(() => import('./ui-components/Change_password'));
const Parish = lazy(() => import('./ui-components/Parish'));
const Forane = lazy(() => import('./ui-components/Forane'));
const GlobalAdmin = lazy(() => import('./ui-components/GlobalAdmin'));
const Country = lazy(() => import('./ui-components/Country'));
const Zone = lazy(() => import('./ui-components/Zone'));
const Region = lazy(() => import('./ui-components/Region'));
const Ward = lazy(() => import('./ui-components/Ward'));
const Kuttayma = lazy(() => import('./ui-components/Kuttayma'));
const CreateMember = lazy(() => import('./ui-components/CreateMember'));
const AlbumMember = lazy(() => import('./ui-components/AlbumMember'));

const CreateMemberParish = lazy(() => import('./ui-components/CreateMemberParish'));

const NewRegistration = lazy(() => import('./ui-components/NewRegistration'));
const ApprovedMembers = lazy(() => import('./ui-components/ApprovedMembers'));
const RejectedMembers = lazy(() => import('./ui-components/RejectedMembers'));
const ActiveMembers = lazy(() => import('./ui-components/ActiveMembers'));
const Verifyemail = lazy(() => import('./ui-components/Verifyemail'));
const ViewMemberdetails = lazy(() => import('./ui-components/ViewMemberdetails')); // Corrected import statement

const Editi = lazy(() => import('./ui-components/Editi')); // Corrected import statement

const Advertisment_view = lazy(() => import('./ui-components/Advertisment_view'));


const Memberbasicinfo = lazy(() => import('./ui-components/Memberbasicinfo'));
const WorkingCommittee = lazy(() => import('./ui-components/WorkingCommittee'));
const ExecutiveCommittee = lazy(() => import('./ui-components/ExecutiveCommittee'));
const EducationCategory = lazy(() => import('./ui-components/EducationCategory'));
const Occupation = lazy(() => import('./ui-components/Occupation'));
const Administration = lazy(() => import('./ui-components/Administration'));
const MessagesWeb = lazy(() => import('./ui-components/MessagesWeb'));
const Data_Settings = lazy(() => import('./ui-components/Data_Settings'));
const EditMemberZone = lazy(() => import('./ui-components/EditMemberZone'));
const CountryWorkingCommittee = lazy(() => import('./ui-components/CountryWorkingCommittee'));
const WorkingCommitteeForane = lazy(() => import('./ui-components/WorkingCommitteeForane'));


const JobVacancy = lazy(() => import('./ui-components/JobVacancy'));
const Client = lazy(() => import('./ui-components/Client'));
const Advertisement = lazy(() => import('./ui-components/Advertisement'));
const News = lazy(() => import('./ui-components/News'));
const Video = lazy(() => import('./ui-components/Video'));
const Albums = lazy(() => import('./ui-components/Albums'));
const Gallery = lazy(() => import('./ui-components/Gallery'));
const EventCalendar = lazy(() => import('./ui-components/EventCalendar'));
const ProfileSettings = lazy(() => import('./ui-components/ProfileSettings'));
const MemberEducationmodified = lazy(() => import('./ui-components/MemberEducationmodified'));
const ConfirmationdataMember = lazy(() => import('./ui-components/ConfirmationdataMember'));

const OccupationModifiedMembers = lazy(() => import('./ui-components/OccupationModifiedMembers'));
const SearchMemberParish = lazy(() => import('./ui-components/SearchMemberParish'));
const SearchMemberZone = lazy(() => import('./ui-components/SearchMemberZone'));


const MemberGallery = lazy(() => import('./ui-components/MemberGallery'));
const Churches = lazy(() => import('./ui-components/Churches'));
const Priests = lazy(() => import('./ui-components/Priests'));
const JobVacancyDisplay = lazy(() => import('./ui-components/JobVacancyDisplay'));

const ChangePasswordUser = lazy(() => import('./ui-components/ChangePassword'));
const Users = lazy(() => import('./ui-components/Users'));

const Forane_directors = lazy(() => import('./ui-components/Forane_directors'));
const DeathdetailsMember = lazy(() => import('./ui-components/DeathdetailsMember'));

const ParishTransferList = lazy(() => import('./ui-components/ParishTransferList'));
//***RINTU*******
const WorkingCommiteeReport = lazy(() => import('./ui-components/reports/WorkingCommiteeReport'));
const ExpatriateReport = lazy(() => import('./ui-components/reports/ExpatriateReport'));
const ExcutiveCommiteeReport = lazy(() => import('./ui-components/reports/ExcutiveCommiteeReport'));
const RepatriateReport = lazy(() => import('./ui-components/reports/RepatriateReport'));
const DeathReport = lazy(() => import('./ui-components/reports/DeathReport'));

const MemberZoneDetails = lazy(() => import('./ui-components/MemberZoneDetails'));
const Profile = lazy(() => import('./ui-components/Profile'));
const MemberNews = lazy(() => import('./ui-components/MemberNews'));

const Login = lazy(() => import('./Login'));
const MembershipForm = lazy(() => import('./MembershipForm'));

const ResetPassword = lazy(() => import('./ResetPassword'));

const MembershipFormParish = lazy(() => import('./MembershipFormParish'));

const OfficeStaff = lazy(() => import('./ui-components/OfficeStaff'));

const ForgotPassword = lazy(() => import('./ForgotPassword'));
const OtpVerification = lazy(() => import('./OtpVerification'));
const UpdatePassword = lazy(() => import('./UpdatePassword'));


function App() {
  return (
    <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
    <Routes>
   
          <Route path="/membership-form/:id" element={<MembershipForm />} />
          
          <Route path="/membership-form-parish/:id" element={<MembershipFormParish />} />
          <Route path="reset-password" element={<ResetPassword />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-otp" element={<OtpVerification />} />
            <Route path="/update-password" element={<UpdatePassword />} />
       
          </Routes>

      <AuthProvider>
        <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/" element={<RequireAuth><MasterLayout /></RequireAuth>}>
            
          <Route path="designations" element={
<RequireRole allowedRoles={['admin', 'forane']}>
  <Designations />
</RequireRole>
} />

<Route index element={<Dashboard />} />







<Route path="editi/:family_member_id" element={  <RequireRole allowedRoles={['zone','parish','admin']}>

<Editi />
</RequireRole>
} />













<Route path="year_setting" element={
<RequireRole allowedRoles={['admin']}>
  <YearSettings />
</RequireRole>
} />

<Route path="change_password" element={
<RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish','office']}>
  <ChangePassword />
</RequireRole>
} />


<Route path="office-staff" element={
             <RequireRole allowedRoles={['admin']}>
            <OfficeStaff/>
            </RequireRole>
            } />
          <Route path="parish"
          
          element={  <RequireRole allowedRoles={['admin']}>
          
          <Parish />
          </RequireRole>
          
          } />
          <Route path="forane"  element={  <RequireRole allowedRoles={['admin']}>
            <Forane />
            </RequireRole>
            } />



<Route path="AlbumMember"  element={  <RequireRole allowedRoles={['admin','member']}>
            <AlbumMember />
            </RequireRole>
            } />
          <Route path="global"  element={  <RequireRole allowedRoles={['admin']}>
            
            <GlobalAdmin />
            </RequireRole>}
            
            
            />

<Route path="country/:zone_details_id" element={  <RequireRole allowedRoles={['admin']}>
<Country />
</RequireRole>
} />
<Route path="zone/:zone_details_id" element={  <RequireRole allowedRoles={['admin']}>

<Zone />
</RequireRole>
} />

<Route path="region/:zone_details_id" element={  <RequireRole allowedRoles={['admin']}>

<Region />
</RequireRole>
} />
<Route path="administration" element={  <RequireRole allowedRoles={['admin']}>

<Administration />
</RequireRole>
} />
          <Route path="messagesweb" element={  <RequireRole allowedRoles={['admin']}>
            
            <MessagesWeb />
            </RequireRole> 
            } />
<Route path="datasettings" element={  <RequireRole allowedRoles={['admin']}>
  <Data_Settings/>
  </RequireRole>
  } />
<Route path="eventcalendar" element={  <RequireRole allowedRoles={['admin']}>
  
  <EventCalendar />
  </RequireRole>
  } />
<Route path="advertisement/:adv_client_id" element={  <RequireRole allowedRoles={['admin']}>
  
  <Advertisement />
  </RequireRole>
  } />
<Route path="video" element={  <RequireRole allowedRoles={['admin']}>
  
  <Video />
  </RequireRole> 
  } />
<Route path="client" element={  <RequireRole allowedRoles={['admin']}>
  
  <Client />
  </RequireRole>
  } />

<Route path="educationcategory" element={  <RequireRole allowedRoles={['admin']}>
<EducationCategory />
</RequireRole>
} />
<Route path="occupation" element={  <RequireRole allowedRoles={['admin']}>
<Occupation />
</RequireRole>
} />

          <Route path="createmember" element={
<RequireRole allowedRoles={[ 'parish', 'zone','office']}>
            <CreateMember />
            </RequireRole>
            } />
          <Route path="creatememberparish" element={ <RequireRole allowedRoles={[ 'parish', 'zone','office']}>
            <CreateMemberParish />
            </RequireRole>
            } />
      
          <Route path="ward" element={  <RequireRole allowedRoles={['parish','office']}>
            <Ward />
            </RequireRole>
            } />
          <Route path="kuttayma/:ward_id" element={  <RequireRole allowedRoles={['parish','office']}>
            <Kuttayma />
            </RequireRole>
            } />
        
          <Route path="newregistration" element={  <RequireRole allowedRoles={['zone','parish','admin','office']}>
            <NewRegistration />
            </RequireRole>
            } />
          <Route path="approvedmembers" element={  <RequireRole allowedRoles={['zone','parish','admin','office']}>
            <ApprovedMembers />
            </RequireRole>
            } />
          <Route path="rejectedmembers" element={  <RequireRole allowedRoles={['zone','parish','admin','office']}>
            <RejectedMembers />
            </RequireRole>
            } />
          <Route path="activemembers" element={  <RequireRole allowedRoles={['zone','parish','admin','office']}>
            <ActiveMembers />
            </RequireRole>
            } />



<Route path="ParishTransferList" element={  <RequireRole allowedRoles={['zone','parish','admin','office']}>
            <ParishTransferList />
            </RequireRole>
            } />

          <Route path="executivecommittee" element={
            <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
            <ExecutiveCommittee />
            </RequireRole>
            } />
          <Route path="workingcommittee" element={
           <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
           <WorkingCommittee />
           </RequireRole> 
            } />
          <Route path="countryworkingcommittee" element={
           <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
           <CountryWorkingCommittee />
           </RequireRole> 
            } />
          <Route path="workingcommitteeforane" element={
            <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
             <WorkingCommitteeForane />
            </RequireRole>
            } />
          <Route path="searchmemberparish" element={
           <RequireRole allowedRoles={[  'parish','office']}>
           <SearchMemberParish />
           </RequireRole>
            } />

<Route path="searchmemberzone" element={
           <RequireRole allowedRoles={[ 'zone']}>
           <SearchMemberZone />
           </RequireRole>
            } />
       
          <Route path="viewmemberdetails/:family_member_id" element={  
            <RequireRole allowedRoles={['parish', 'zone','admin','member','office' ]}>

             <ViewMemberdetails />
              </RequireRole>
             } />







          <Route path="memberbasicinfo/:family_member_id" element={
   <RequireRole allowedRoles={['parish', 'zone','admin','office' ]}>
            <Memberbasicinfo />
            </RequireRole>
          }/>

          <Route path="editmemberzone/:membership_zone_details_id" element={
             <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish','office']}>
            <EditMemberZone />
            </RequireRole>
            } />
        

          <Route path="album" element={
             <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish','office']}>
            <Albums />
            </RequireRole>
            } />
          <Route path="gallery/:album_id" element={
             <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish','office']}>
            <Gallery/>
            </RequireRole>
            } />
     
           <Route path="jobvacancy" element={
             <RequireRole allowedRoles={['admin',  'zone',]}>
            <JobVacancy />
            </RequireRole>
            } />
     
        <Route path="news" element={
           <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
          <News />
          </RequireRole>
          } />

     
        <Route path="profilesettings" element={
               <RequireRole allowedRoles={['zone']}>
          <ProfileSettings />
          </RequireRole>
          } />
       

       <Route path="MemberNews" element={
               <RequireRole allowedRoles={['member']}>
          <MemberNews />
          </RequireRole>
          } />
       

 <Route path="membereducationmodified" element={
        <RequireRole allowedRoles={['zone','parish']}>
  <MemberEducationmodified />
  </RequireRole>
  } />
 <Route path="occupationmodifiedmembers" element={
        <RequireRole allowedRoles={['zone','parish']}>
  <OccupationModifiedMembers />
  </RequireRole>
  } />
 
 
<Route path="confirmationdatamember/:family_member_id" element={
<RequireRole allowedRoles={['zone','parish']}>
<ConfirmationdataMember />
</RequireRole>
} />


<Route path="churches" element={
  <RequireRole allowedRoles={['member','admin']}>
<Churches />
</RequireRole>
} />

<Route path="Advertisment_view" element={
  <RequireRole allowedRoles={['admin']}>
<Advertisment_view />
</RequireRole>
} />


<Route path="priests" element={
  <RequireRole allowedRoles={['member','admin','parish']}>
<Priests />
</RequireRole>
} />
<Route path="membergallery/:album_id" element={
      <RequireRole allowedRoles={['member']}>
  <MemberGallery />
  </RequireRole>
  } />


<Route path="jobvacancydisplay" element={
    <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
<JobVacancyDisplay />
</RequireRole>
} />
<Route path="changepassword" element={
     <RequireRole allowedRoles={['admin', 'forane', 'zone', 'country', 'member', 'office', 'global','parish']}>
<ChangePasswordUser />
</RequireRole>
} />

<Route path="users" element={
    <RequireRole allowedRoles={['parish']}>
<Users />
</RequireRole>
} />

<Route path="verifyemail" element={<Verifyemail />} />

<Route path="profile" element={<Profile />} />

<Route path="working-commitee-report" element={
              <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone']}>
                <WorkingCommiteeReport />
              </RequireRole>
            } />

<Route path="expatriate-report" element={
              <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone']}>
                <ExpatriateReport />
              </RequireRole>
            } />

            <Route path="excutive-commitee-report" element={
              <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone']}>
                <ExcutiveCommiteeReport />
              </RequireRole>
            } />


<Route path="repatriate-report" element={
              <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone']}>
                <RepatriateReport />
              </RequireRole>
            } />

<Route path="death-report" element={
              <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone','office']}>
                <DeathReport />
              </RequireRole>
            } />


<Route path="Forane_directors" element={
                <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone']}>
                  <Forane_directors />
                </RequireRole>
              } />

<Route path="DeathdetailsMember" element={
                <RequireRole allowedRoles={['admin', 'forane','global','country','parish','zone','office']}>
                  <DeathdetailsMember />
                </RequireRole>
              } />


<Route path="memberzonedetails" element={  
            <RequireRole allowedRoles={['parish', 'zone','admin','office' ]}>

             <MemberZoneDetails />
              </RequireRole>
             } />

          
          </Route>
          
        </Routes>
      </AuthProvider>
    </Suspense>
  </BrowserRouter>


  );
}

export default App;
